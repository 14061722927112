@font-face {
  font-family: 'PwC Helvetica Neue_n2';
  src: url('./fonts/418e7417-47f3-40a1-8817-519a566f9d82.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/418e7417-47f3-40a1-8817-519a566f9d82.eot?#iefix');
  src: url('./fonts/418e7417-47f3-40a1-8817-519a566f9d82.eot?#iefix') format('eot'),
      url('./fonts/02b3f97a-406d-43cb-885c-ba5233577cad.woff2') format('woff2'),
      url('./fonts/cad33bbd-bbf2-423b-98c5-51136c054e14.woff') format('woff'),
      url('./fonts/22c6282d-e7aa-406b-89dc-5176916a9c44.ttf') format('truetype');
  font-weight: 275;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n2';
  src: url('./fonts/da266c3a-a094-4adc-a81e-12f73609ba16.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/da266c3a-a094-4adc-a81e-12f73609ba16.eot?#iefix');
  src: url('./fonts/da266c3a-a094-4adc-a81e-12f73609ba16.eot?#iefix') format('eot'),
      url('./fonts/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2') format('woff2'),
      url('./fonts/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff') format('woff'),
      url('./fonts/922917a9-4ca8-4c0b-b1bb-417b6ff0210e.ttf') format('truetype');
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n3';
  src: url('./fonts/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix');
  src: url('./fonts/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot?#iefix') format('eot'),
      url('./fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2') format('woff2'),
      url('./fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff') format('woff'),
      url('./fonts/fdf10d5e-4ce0-45e5-a6c1-5eaff6cb1c11.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n4';
  src: url('./fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix');
  src: url('./fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix') format('eot'),
      url('./fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
      url('./fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff'),
      url('./fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n5';
  src: url('./fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix');
  src: url('./fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix') format('eot'),
      url('./fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2') format('woff2'),
      url('./fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff') format('woff'),
      url('./fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n7';
  src: url('./fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix');
  src: url('./fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix') format('eot'),
      url('./fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2'),
      url('./fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff') format('woff'),
      url('./fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PwC Helvetica Neue_n9';
  src: url('./fonts/4cc8f5da-4e24-4929-8efb-866ffcb1fe7e.eot?#iefix') format('eot');
}

@font-face {
  font-family: 'PwC Helvetica Neue';
  src: url('./fonts/4cc8f5da-4e24-4929-8efb-866ffcb1fe7e.eot?#iefix');
  src: url('./fonts/4cc8f5da-4e24-4929-8efb-866ffcb1fe7e.eot?#iefix') format('eot'),
      url('./fonts/93ce67e5-64e3-4a99-99a3-545f20c5beec.woff2') format('woff2'),
      url('./fonts/96b3a113-3e18-46f9-b6e3-5bdee7288b20.woff') format('woff'),
      url('./fonts/556d95c6-2352-4272-9422-83d53f66cfa3.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
